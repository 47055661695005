import React, { useState, useEffect } from 'react'
import { EnergyStreamDataSource, ViewLayout, fetchLocalLayout, MobileMeterView, MobileLiveChartView, MobileHistoricChartView, MobileOverview } from 'apparent-react'
import { Button, Dropdown, Nav, NavDropdown } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'
import {isMobile} from 'react-device-detect';

const OnlineStatus = ({online}) => {
  return (
    <span>
      <span className={online ? `online-circle` : `offline-circle`} />{'\u00A0'}
      <span><strong>{online ? `Online` : `Offline`}</strong></span>{'\u00A0\u00A0'}
    </span>
  )
}

const Timer = ({isPortrait, jsonLayoutTimezone}) => {
  const [time, setTime] = useState(null)
  const [timezone, setTimezone] = useState(jsonLayoutTimezone ? jsonLayoutTimezone : "America/Los_Angeles")
  const mTop = isPortrait ? '0vh' : '4vh';

  useEffect(() => {
    setTimezone(jsonLayoutTimezone)
  }, [jsonLayoutTimezone])

  useEffect(() => {
    const intervalID = setInterval(() => {
      let d = new Date()
      let options = {}
      if (!isPortrait) {
        options = {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          timeZone: timezone,
          timeZoneName: "short"
        }
      } else {
        options = {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          timeZone: timezone,
          timeZoneName: "short"
        }
      }
      setTime(d.toLocaleString("en", options))
    }, 500);
    return () => {
      clearInterval(intervalID);
    };
  }, [timezone])
  return (<div style={{float: 'right', marginTop: mTop, marginRight: '2vw', fontSize: '2.0vh', width: '20%'}}>{time}</div>)
}

const Home = ({apiHost, loggedInState, layouts, icon, selectedLayoutState}) => {
  const [selectedLayout, setSelectedLayout] = selectedLayoutState
  const onlineState = useState(true)
  // const isTabletOrMobile = useMediaQuery({ maxDeviceWidth: 900 })
  const isPortrait = useMediaQuery({ query : '(orientation: portrait)'})
  const online = onlineState[0]
  const [loggedIn, setLoggedIn] = loggedInState
  const [jsonLayoutTimezone, setJsonLayoutTimezone] = useState('America/Los_Angeles')
  const viewsState = useState('layout');
  const [viewState, setViewState] = viewsState;
  const mobileViewsState = useState('overview');
  const [mobileViewState, setMobileViewState] = mobileViewsState;

  const dataSource = new EnergyStreamDataSource(apiHost, setLoggedIn)
  // fetch layouts from local filesystem in development mode
  if (process.env.NODE_ENV === "development") {
    dataSource.fetchLayout = fetchLocalLayout
  }
  const navigate = useNavigate()

  const layoutHandler = (_jsonLayout) => {
    setJsonLayoutTimezone(_jsonLayout['timezone'] ? _jsonLayout['timezone'] : 'America/Los_Angeles')
  }

  useEffect(() => {
    if (selectedLayout !== "") {
      localStorage.setItem('selectedLayout', selectedLayout);
      dataSource.fetchLayout(selectedLayout, layoutHandler);
      setMobileViewState('overview');
      setViewState('layout');
    }
  }, [selectedLayout])

  useEffect(() => {
    if (layouts === undefined)
        return
    localStorage.setItem('layouts', JSON.stringify(layouts));
    if (selectedLayout === "" || !Object.keys(layouts).includes(selectedLayout)) {
        setSelectedLayout(Object.keys(layouts)[0])
    }
  }, [layouts])

  useEffect(() => {
    if (!loggedIn) {
      navigate('/login')
    }
  }, [loggedIn])

  const logout = () => {
    const url = [apiHost, 'api/logout'].join('/')
    const config = {headers: {'Content-Type': 'application/json'}, timeout: 2000}
    axios
    .get(url, config).then((response) => {})
    .catch((error) => {
        console.log("Unable to properly logout.")
    })

    setLoggedIn(false)
    navigate('/logout')
  }

  //style={{width: '30vw', backgroundColor: 'black', color: 'white'}}

  const checkHeader = () => {
    if (isMobile) { 
      if (!isPortrait){
        return (
          <div>
            <p>
              <OnlineStatus online={online} />
              <span>
                  <Form.Control as="select" value={selectedLayout} custom onChange={e => setSelectedLayout(e.target.value)}>
                      {Object.keys(layouts).map((filename, i) => (
                        <option value={filename} key={i}>{layouts[filename]}</option>
                      ))}
                  </Form.Control>
              </span>
              <img style={{marginLeft: '10vw'}} src={icon} className={'logo'} alt={"Apparent Logo"}/>
            </p>
          </div>
        );
      } else {
        return (
          <p>
            <OnlineStatus online={online} />
            <span>
                <Form.Control as="select" value={selectedLayout} custom style={{ width: '30vw'}} onChange={e => setSelectedLayout(e.target.value)}>
                    {Object.keys(layouts).map((filename, i) => (
                      <option value={filename} key={i}>{layouts[filename]}</option>
                    ))}
                </Form.Control>
            </span>
          </p>
        );
      }
    }
    return (
      <p>
        <OnlineStatus online={online} />
        <span>
            <Form.Control as="select" value={selectedLayout} custom onChange={e => setSelectedLayout(e.target.value)}>
                {Object.keys(layouts).map((filename, i) => (
                  <option value={filename} key={i}>{layouts[filename]}</option>
                ))}
            </Form.Control>
        </span>
      </p>
    );
  }

  const checkFooter = () => {
    if (isMobile && !isPortrait) {
      return;
    }
    return (
      <footer className={'footer'}>
        <img src={icon} className={'logo'} alt={"Apparent Logo"}/>
        <Timer isPortrait={isPortrait} jsonLayoutTimezone={jsonLayoutTimezone}/>
      </footer>
    );
  }

  const handleSelectMobile = (eventKey) => {
    if (eventKey === 'logout') {
      logout();
    } else if (eventKey === 'overview') {
      setMobileViewState('overview');
    }else if (eventKey === 'meterView') {
      setMobileViewState('meter');
    } else if (eventKey === 'liveChartView') {
      setMobileViewState('liveChart');
    } else if (eventKey === 'historicChartView') {
      setMobileViewState('historicChart');
    } else {
      setMobileViewState('layout')
    }
  }
  const handleSelect = (eventKey) => {
    if (eventKey === 'logout') {
      logout();
    } else if (eventKey === 'meterView') {
      setViewState('meter');
    } else {
      setViewState('layout');
    }
  }
  const checkMenu = () => {
    if (isMobile) {
      return (
        <Nav variant='pills' onSelect={handleSelectMobile}>
          <NavDropdown style={{zIndex: '5'}} title='Menu' id='basic-nav-dropdown' active>
            <NavDropdown.Item style={{width:'150px'}} eventKey='overview' active={false}>Overview</NavDropdown.Item>
            <NavDropdown.Item style={{width:'150px'}} eventKey='layoutView' active={false}>Site Layout</NavDropdown.Item>
            <NavDropdown.Item style={{width:'150px'}} eventKey='liveChartView' active={false}>Live Chart</NavDropdown.Item>
            <NavDropdown.Item style={{width:'150px'}} eventKey='historicChartView' active={false}>Historic Chart</NavDropdown.Item>
            <NavDropdown.Item style={{width:'150px'}} eventKey='meterView' active={false}>Meter Table</NavDropdown.Item>
            <NavDropdown.Item style={{width:'150px'}} eventKey='logout' active={false}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      )
    } else {
      return (
        <div>
          <Dropdown style={{float: 'left', marginRight: '1vw'}}>
            <Dropdown.Toggle style={{background: 'transparent', border: 'transparent', padding: '0', marginTop: '0.15rem'}}>
              <span className="fa-stack fa-1x">
                <i style={{fontSize: 'x-large', color: 'white'}} className="fa fa-circle fa-stack-1x"></i>
                <i style={{fontSize: 'xx-large', color: '#007bff'}} className="fa fa-question-circle fa-stack-1x" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{minWidth: '20rem'}} alignRight='true'>
            <Dropdown.ItemText>Export + / Import -</Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.ItemText>To change the readings, click on the number showing, and select the new reading.</Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.ItemText>Exporting values are green, importing are orange.</Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.ItemText>To view the graphs and table, click on the meter you want to see, avoiding the numbers.</Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.ItemText>To close the graphs and table, click within the site, avoiding meters.</Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.ItemText>An "S" within a component signals a subsite. Clicking on the component will open the subsite.</Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.ItemText>The "V%" and "A%" in the meter readings represent Voltage Total Harmonic Disruption (THD) and Current THD respectively.</Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
          <Nav variant='pills' onSelect={handleSelect}>
            <NavDropdown title='Menu' id='basic-nav-dropdown' active>
              <NavDropdown.Item style={{width:'150px'}} eventKey='meterView' active={false}>Meter Table and Chart</NavDropdown.Item>
              <NavDropdown.Item style={{width:'150px'}} eventKey='layoutView' active={false}>Site Layout</NavDropdown.Item>
              <NavDropdown.Item style={{width:'150px'}} eventKey='logout' active={false}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>
      );
    }
  }

  const checkMobileViewState = () => {
    if (isPortrait) {
      return (
        <div style={{ 
          height: '83vh', 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}>
          <strong style={{ margin: '20px' }}>Please rotate phone. This website is only designed to be viewed in landscape mode.</strong>
        </div>
      )
    }
    if (mobileViewState === 'overview'  && isMobile){
      return (
        <MobileOverview dataSource={dataSource}
                  selectedLayout={selectedLayout}
                  onlineState={onlineState}
                  viewStateMenu={viewsState}
                  mobileViewState={mobileViewsState}>
        </MobileOverview>
      );
    }
    else if (mobileViewState === 'layout' && isMobile){
      return (
        <ViewLayout dataSource={dataSource}
                  selectedLayout={selectedLayout}
                  onlineState={onlineState}
                  isTabletOrMobile={isMobile}
                  viewStateMenu={viewsState}>
        </ViewLayout>
      );
    } else if (mobileViewState === 'meter' && isMobile) {
      return (
        <MobileMeterView dataSource={dataSource}
                  selectedLayout={selectedLayout}
                  onlineState={onlineState}/>
      )
    } else if (mobileViewState === 'liveChart' && isMobile) {
      return (
        <MobileLiveChartView dataSource={dataSource}
                  selectedLayout={selectedLayout}
                  onlineState={onlineState}/>
      )
    } else if (mobileViewState === 'historicChart' && isMobile) {
      return (
        <MobileHistoricChartView dataSource={dataSource}
                  selectedLayout={selectedLayout}
                  onlineState={onlineState}/>
      )
    } else {
      return (
        <ViewLayout dataSource={dataSource}
                  selectedLayout={selectedLayout}
                  onlineState={onlineState}
                  isTabletOrMobile={isMobile}
                  viewStateMenu={viewsState}>
        </ViewLayout>
      );
    }
  }

  return (<div style={{overflow: 'hidden'}}>
      <div className={'header header-text'}>
        {checkHeader()}
        <div style={{paddingRight: '25px'}}>
            {checkMenu()}
        </div>
      </div>
      {checkMobileViewState()}
      {checkFooter()}
    </div>
  )
}

export default Home

