/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { VectorChart } from '../charts/vector.js';

import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import { formatValue, readingsMap } from '../readings';

const CellReading = ({ name, value, viewProps }) => {
  const [lastReading, setLastReading] = useState('-');
  const [selectedReadings, setSelectedReadings] =
    viewProps.selectedReadingsState;
  const [views, setViews] = viewProps.viewsState;
  const viewMap = viewProps.viewMap;
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [color, setColor] = useState('black');

  useEffect(() => {
    setLastReading(value);
  }, [value]);

  useEffect(() => {
    if (selectedReadings.includes(name)) {
      setColor('yellow');
    } else {
      setColor('black');
    }
  }, [selectedReadings]);

  const onClick = (e, reading) => {
    if (selectedReadings.includes(reading)) {
      const readings = selectedReadings.filter((r) => r !== reading);
      setSelectedReadings(readings);
      if (readings.length === 0) {
        setSelectedReadings(['kw_reading']);
        setSelectedUlids([]);
        setViews([viewMap.site]);
      }
    } else {
      if (!reading.includes('power_factor')) {
        setSelectedReadings([reading, ...selectedReadings]);
      }
    }
  };

  const renderReading = (name, _value, readingsModifier) => {
    if (isNaN(_value)) return '';

    const [formattedValue, unit] = formatValue(
      _value,
      readingsMap[name],
      readingsModifier
    );
    return `${formattedValue} ${unit}`;
  };

  return (
    <td
      onClick={(e) => onClick(e, name)}
      className='meter-view'
      style={{ borderColor: `${color}`, textAlign: 'right' }}
    >
      {renderReading(name, value, 1)}
    </td>
  );
};

CellReading.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  viewProps: PropTypes.object
};

export const MeterView = ({ jsonLayout, viewProps }) => {
  const [readings, setReadings] = useState({});
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [ulid, setUlid] = useState(selectedUlids[0]);
  const [name, setName] = useState('');
  const [displayState, setDisplayState] = useState('table');

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) => {
      setReadings(e.detail?.[ulid] || {});
    });
    return () => unsubscribe(meterReadingsEventName);
  }, [ulid]);

  useEffect(() => {
    let ulid = null;
    let min_index = 100000;
    selectedUlids.forEach((element, index) => {
      let component = null;
      if (viewProps.subsite) {
        component = jsonLayout[viewProps.subsite].find(
          (c) => c.props.ulid === element
        );
      } else {
        component = jsonLayout.layout.find((c) => c.props.ulid === element);
      }
      if (component) {
        const type = component.component.toLowerCase();
        if (type != 'irradiance') {
          if (index < min_index) {
            ulid = element;
            min_index = index;
          }
        }
      }
    });
    setUlid(ulid);
  }, [selectedUlids]);

  useEffect(() => {
    setName(readings.name);
  }, [readings]);

  return (
    <div className='darktable-container'>
      <div style={{ display: 'inline-flex', width: '33%' }}>
        <Button
          onClick={() =>
            setDisplayState(displayState == 'chart' ? 'table' : 'chart')
          }
          style={{ fontWeight: '600', fontSize: '14px' }}
        >
          {displayState == 'chart' ? 'Meter Table' : 'Vector Chart'}
        </Button>
      </div>
      <div
        style={{
          fontSize: 'initial',
          fontWeight: 'bold',
          display: 'inline-flex',
          marginLeft: '50px'
        }}
      >
        {name} Meter
      </div>
      {displayState == 'chart' ? (
        <div style={{ height: '90%' }}>
          <VectorChart jsonLayout={jsonLayout} viewProps={viewProps} />
        </div>
      ) : (
        <table
          className='darktable'
          style={{
            marginTop: '-2vh',
            width: '100%'
          }}
        >
          <thead>
            <tr>
              <th> </th>
              <th>Phase A</th>
              <th>Phase B</th>
              <th>Phase C</th>
              <th>Aggregate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='meter-view row-header'>Real Power</td>
              <CellReading
                name='kw_reading_a'
                value={readings['kw_reading_a']}
                viewProps={viewProps}
              />
              <CellReading
                name='kw_reading_b'
                value={readings['kw_reading_b']}
                viewProps={viewProps}
              />
              <CellReading
                name='kw_reading_c'
                value={readings['kw_reading_c']}
                viewProps={viewProps}
              />
              <CellReading
                name='kw_reading'
                value={readings['kw_reading']}
                viewProps={viewProps}
              />
            </tr>
            <tr>
              <td className='meter-view row-header'>Reactive Power</td>
              <CellReading
                name='kvar_reading_a'
                value={readings['kvar_reading_a']}
                viewProps={viewProps}
              />
              <CellReading
                name='kvar_reading_b'
                value={readings['kvar_reading_b']}
                viewProps={viewProps}
              />
              <CellReading
                name='kvar_reading_c'
                value={readings['kvar_reading_c']}
                viewProps={viewProps}
              />
              <CellReading
                name='kvar_reading'
                value={readings['kvar_reading']}
                viewProps={viewProps}
              />
            </tr>
            <tr>
              <td className='meter-view row-header'>Apparent Power</td>
              <CellReading
                name='kva_reading_a'
                value={readings['kva_reading_a']}
                viewProps={viewProps}
              />
              <CellReading
                name='kva_reading_b'
                value={readings['kva_reading_b']}
                viewProps={viewProps}
              />
              <CellReading
                name='kva_reading_c'
                value={readings['kva_reading_c']}
                viewProps={viewProps}
              />
              <CellReading
                name='kva_reading'
                value={readings['kva_reading']}
                viewProps={viewProps}
              />
            </tr>
            <tr>
              <td className='meter-view row-header'>Voltage LN</td>
              <CellReading
                name='volts_reading_an'
                value={readings['volts_reading_an']}
                viewProps={viewProps}
              />
              <CellReading
                name='volts_reading_bn'
                value={readings['volts_reading_bn']}
                viewProps={viewProps}
              />
              <CellReading
                name='volts_reading_cn'
                value={readings['volts_reading_cn']}
                viewProps={viewProps}
              />
              <CellReading
                name='volts_reading_ln'
                value={readings['volts_reading_ln']}
                viewProps={viewProps}
              />
            </tr>
            <tr>
              <td className='meter-view row-header'>Voltage LL</td>
              <CellReading
                name='volts_reading_ab'
                value={readings['volts_reading_ab']}
                viewProps={viewProps}
              />
              <CellReading
                name='volts_reading_bc'
                value={readings['volts_reading_bc']}
                viewProps={viewProps}
              />
              <CellReading
                name='volts_reading_ac'
                value={readings['volts_reading_ac']}
                viewProps={viewProps}
              />
              <CellReading
                name='volts_reading'
                value={readings['volts_reading']}
                viewProps={viewProps}
              />
            </tr>
            <tr>
              <td className='meter-view row-header'>Current</td>
              <CellReading
                name='amps_reading_a'
                value={readings['amps_reading_a']}
                viewProps={viewProps}
              />
              <CellReading
                name='amps_reading_b'
                value={readings['amps_reading_b']}
                viewProps={viewProps}
              />
              <CellReading
                name='amps_reading_c'
                value={readings['amps_reading_c']}
                viewProps={viewProps}
              />
              <CellReading
                name='amps_reading'
                value={readings['amps_reading']}
                viewProps={viewProps}
              />
            </tr>
            <tr>
              <td className='meter-view row-header'>Power Factor</td>
              <CellReading
                name='power_factor_a'
                value={readings['power_factor_a']}
                viewProps={viewProps}
              />
              <CellReading
                name='power_factor_b'
                value={readings['power_factor_b']}
                viewProps={viewProps}
              />
              <CellReading
                name='power_factor_c'
                value={readings['power_factor_c']}
                viewProps={viewProps}
              />
              <CellReading
                name='power_factor'
                value={readings['power_factor']}
                viewProps={viewProps}
              />
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

MeterView.propTypes = {
  jsonLayout: PropTypes.object,
  viewProps: PropTypes.object
};
