/* eslint-disable @shopify/jsx-no-hardcoded-content */
/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { renderReading } from '../readings.js';
import { isOnline } from '../helpers/isOnline.js';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import { Arrow } from './arrow.js';

export const Meter = ({
  name,
  ulid,
  xaxis,
  yaxis,
  readingsModifier,
  titlePosition,
  viewProps,
  overrideName,
  aggregateMeter,
  arrowLocation,
  timezone,
  constantDirection,
  isTabletOrMobile,
  subsite,
  isSubsite
}) => {
  const [readings, setReadings] = useState({});
  const [meterName, setMeterName] = useState(overrideName || name);
  const [meterReading, setMeterReading] = useState('');
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(false);
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [views, setViews] = viewProps.viewsState;
  const viewMap = viewProps.viewMap;
  const [color, setColor] = useState('white');
  const [arrowDirection, setArrowDirection] = useState('away');

  const directionReading = 'kw_reading';

  const positionWithStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) => {
      setReadings(e.detail?.[ulid] || {});
    });
    return () => unsubscribe(meterReadingsEventName);
  }, [ulid]);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (Object.keys(readings).length !== 0) {
      _online = isOnline(parseInt(readings?.read_at || '0') * 1000);
      if (!overrideName && 'name' in readings) {
        setMeterName(readings?.name || meterName);
      } else if (overrideName) {
        setMeterName(overrideName);
      }
      if (selectedReading in readings)
        setMeterReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            readings,
            _online,
            timezone,
            isTabletOrMobile,
            readingsModifier
          )
        );
      if (directionReading in readings) {
        if (constantDirection) {
          setArrowDirection(constantDirection);
        } else {
          if (readings[directionReading] > 0) {
            setArrowDirection('away');
          } else {
            setArrowDirection('into');
          }
        }
      }
    } else {
      setMeterName(name);
      setMeterReading('');
    }
    setOnline(_online);
  }, [readings, selectedReading, ulid]);

  const offlineClass = (className) => (!online ? className : '');

  const onClick = (e, ulid) => {
    if (!isTabletOrMobile) {
      if (subsite) {
        if (isSubsite) {
          if (selectedUlids.includes(ulid)) {
            const ulids = selectedUlids.filter((u) => u !== ulid);
            setSelectedUlids(ulids);
            if (ulids.length === 0) {
              setViews([viewMap.subsite, subsite]);
            }
          } else {
            setViews([viewMap.subsite, viewMap.meter, viewMap.chart, subsite]);
            setSelectedUlids([ulid, ...selectedUlids]);
          }
        } else {
          setViews([viewMap.subsite, subsite]);
        }
      } else {
        if (selectedUlids.includes(ulid)) {
          const ulids = selectedUlids.filter((u) => u !== ulid);
          setSelectedUlids(ulids);
          if (ulids.length === 0) {
            setViews([viewMap.site]);
          }
        } else {
          setViews([viewMap.site, viewMap.meter, viewMap.chart]);
          setSelectedUlids([ulid, ...selectedUlids]);
        }
      }
    }
    e.stopPropagation();
  };

  const gray = '#474747';

  useEffect(() => {
    if (selectedUlids.includes(ulid)) {
      setColor('yellow');
    } else if (!online) {
      setColor(gray);
    } else {
      setColor('white');
    }
  }, [online, selectedUlids]);

  const onMouseOver = () => {
    if (!selectedUlids.includes(ulid)) setColor('yellow');
  };

  const onMouseOut = () => {
    if (!selectedUlids.includes(ulid))
      online ? setColor('white') : setColor(gray);
  };

  return (
    <div
      className='apparent-module meter'
      onClick={(e) => onClick(e, ulid)}
      style={positionWithStyle}
      onMouseOut={(e) => onMouseOut(e)}
      onMouseOver={(e) => onMouseOver(e)}
    >
      {(titlePosition === undefined || titlePosition === 'top') && (
        <div
          className='meter-name'
          style={{ marginTop: '13px', color: `${color}` }}
        >
          {meterName}
        </div>
      )}
      {titlePosition === 'left' && (
        <div
          className='meter-name'
          style={{
            position: 'absolute',
            marginLeft: `-${meterName.length * 12}px`,
            marginTop: '50px',
            color: `${color}`
          }}
        >
          {meterName}
        </div>
      )}
      {titlePosition === 'right' && (
        <div
          className='meter-name'
          style={{
            position: 'absolute',
            marginLeft: `125px`,
            width: `${meterName.length * 12}px`,
            marginTop: '50px',
            color: `${color}`
          }}
        >
          {meterName}
        </div>
      )}
      <div
        className={`${
          aggregateMeter != 'true'
            ? 'meter-block'
            : 'meter-aggregate-main-wrapper'
        }`}
        style={{ borderColor: `${color}` }}
      >
        {aggregateMeter === 'true' ? (
          <div
            className='meter-aggregate-wrapper'
            style={{ borderColor: `${color}` }}
          >
            <div
              className='meter-aggregate-1'
              style={{ borderColor: `${color}` }}
            />
            <div
              className='meter-aggregate-2'
              style={{ borderColor: `${color}` }}
            />
            <img
              src='/favicon_apparent.png'
              className='meter-aggregate-logo'
              alt='Apparent Logo'
            />
          </div>
        ) : (
          <div />
        )}
        <div
          className='meter-readings'
          style={{ zIndex: 1 }}
          //   transform: `${aggregateMeter != 'true' ? '' : 'rotate(-45deg)'}`
          // }}
        >
          <div style={{ position: 'absolute', top: '39%', left: '2px' }}>
            {meterReading}
          </div>
        </div>
      </div>
      {titlePosition === 'bottom' && (
        <div className='meter-name' style={{ color: `${color}` }}>
          {meterName}
        </div>
      )}
      <Arrow
        location={arrowLocation}
        direction={arrowDirection}
        component='meter'
        online={online}
        verticalOffset={titlePosition === 'bottom' ? -39 : 0}
      />
    </div>
  );
};

Meter.propTypes = {
  name: PropTypes.string,
  ulid: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  readingsModifier: PropTypes.number,
  titlePosition: PropTypes.string,
  viewProps: PropTypes.object,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool,
  overrideName: PropTypes.string,
  aggregateMeter: PropTypes.string,
  arrowLocation: PropTypes.string,
  constantDirection: PropTypes.string,
  subsite: PropTypes.string,
  isSubsite: PropTypes.bool
};
