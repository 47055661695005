import axios from "axios";
import React, { useState  } from 'react'
import {Button} from "react-bootstrap"
import { useNavigate } from "react-router-dom"

import "./Login.css"

export default function Login({apiHost, loggedInState, setLayouts, setSelectedLayout, setIcon}) {

    const navigate = useNavigate();

    const setLoggedIn = loggedInState[1]

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const onSubmit = e => {
        e.preventDefault()
        let data = JSON.stringify({
            username: username,
            password: password
        })

        let url = apiHost + "/api/login"
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'User'
            },
            timeout: 10000
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.success) {
                    setLoggedIn(true)
                    let fordUrl = apiHost + "/api/fetch-ford-token"
                    axios.post(fordUrl, {
                        headers: {
                            'Content-Type' : 'application/json',
                        },
                        timeout: 10000
                    }).then((response) => {
                        if (response.status === 200) {
                            console.log('Successfully got Ford access token')
                        }
                        else {
                            console.log('Failed to get Ford access token')
                        }
                    }).catch((response) => {
                        console.log(response)
                    })
                    const layouts = response.data.layouts
                    const icon = response.data.icon

                    setLayouts(layouts)
                    setIcon(icon)
                    setSelectedLayout(Object.keys(layouts)[0])
                    navigate('/')
                } else {
                    setLoggedIn(false)
                    console.log(response.data.message)
                }
            }
        }).catch((response) => {
            console.log(`{response.message}`)
            alert("Username or password does not not match")
            navigate('/login')
        });
    }

    return (
        <div className="login_wrapper">
            <section className="login_content">
                <form onSubmit={onSubmit}>
                    <div style={{margin: "10px"}}>
                        <img src={"/apparent.png"} style={{height: "12vh"}} alt={"Apparent Logo"}/>
                    </div>
                    <div>
                        <input autoFocus type="text" className="form-control" placeholder="username" required=""
                               value={username}
                               onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div>
                        <input type="password" className="form-control" placeholder="password" required=""
                               value={password}
                               onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div>
                        <Button className="display-3-button" type="submit" style={{height: "40px", fontWeight: 500, width: "100%"}}>
                            Login
                        </Button>
                    </div>
                </form>
            </section>
        </div>)
}
